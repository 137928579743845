<template>
  <div class="w-1200 mt-5 mb-5">
    <el-steps :active="3" simple>
      <el-step title="选择商品" icon="iconfont icon-gouwuche"></el-step>
      <el-step title="填写订单信息" icon="el-icon-edit"></el-step>
      <el-step title="提交订单" icon="el-icon-upload"></el-step>
    </el-steps>
    <div class="pay-box">
      <div class="price">￥{{orderInfo.realTotalAmount || orderInfo.totalAmount}}</div>
      <div class="pay-method">
        <el-radio-group v-model="payMethod">
          <el-radio :label="1">微信支付</el-radio>
          <el-radio :label="2">支付宝支付</el-radio>
          <el-radio :label="3">余额支付</el-radio>
        </el-radio-group>
      </div>
      <div class="" style="float: right;">
        <el-button type="danger" @click="beforePay">支付</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import { createOrderByCartUrl,createOrderUrl,createOrderBySeckillUrl } from '@/api/index'

export default {
  name: 'Pay',
  data () {
    return {
      // 支付方式
      payMethod: 0,
      // cart是从购物车进来的，goods是从商品详情页进来的，order是从未付款订单页面进来的
      pageType: '',
      orderInfo: {},
      beforePayHttpObj: {
        // 类型：JSAPI—JSAPI支付（或小程序支付）、NATIVE—Native支付、APP—app支付，MWEB—H5支付
        trade_type: 'MWEB—H5',
        // 咱们自己商城的订单号
        out_trade_no: ''
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      let token = window.localStorage.getItem('token')
      if(token == '' || token==null){
        this.$router.push('/login')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.createOrder()
      setTimeout(()=>{
        this.changeLoading(loading)
      },300)
    },
    // 关闭loading弹窗
    changeLoading(loading){
      loading.close();
    },
    // 创建订单
    createOrder () {
      if (this.$route.query.type != undefined) {
        this.pageTyped = this.$route.query.type
      } else {
        this.$message.error('订单异常，将自动返回购物车页面')
        this.$router.push({ path: '/cart' })
        return false
      }
      switch (this.pageTyped) {
        case 'cart':
          this.cartOrderInfo()
          break
        case 'goods':
          this.directBuyOrderInfo()
          break
        case 'order':
          this.getPaidOrderInfo()
          break
        case 'seckill':
          this.seckillBuyOrderInfo()
          break
        default:
          this.$message.error('订单异常，将自动返回购物车页面')
          this.$router.push({ path: '/cart' })
          break
      }
    },
    // 购物车创建订单信息
    async cartOrderInfo () {
      let cartOrderHttpObj = this.$store.state.cartOrderHttpObj
      if (JSON.stringify(cartOrderHttpObj) == '{}') {
        this.$message.error('订单异常，将自动返回购物车页面')
        await this.$router.push({ path: '/cart' })
        return false
      }
      const { data: res } = await this.$http.post(createOrderByCartUrl, cartOrderHttpObj)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.orderInfo = res.data
      this.beforePayHttpObj.out_trade_no = this.orderInfo.no
    },
    // 直接购买创建订单信息
    async directBuyOrderInfo(){
      let directBuyOrderHttpObj = this.$store.state.goodsDirectBuyHttpObj
      if (JSON.stringify(directBuyOrderHttpObj) == '{}') {
        this.$message.error('订单异常，将自动返回商品详情页')
        this.$router.go(-2)
        return false
      }
      const { data: res } = await this.$http.post(createOrderUrl, directBuyOrderHttpObj)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.orderInfo = res.data
      this.beforePayHttpObj.out_trade_no = this.orderInfo.no
    },
    // 秒杀商品创建订单信息
    async seckillBuyOrderInfo(){
      let directBuyOrderHttpObj = this.$store.state.goodsDirectBuyHttpObj
      if (JSON.stringify(directBuyOrderHttpObj) == '{}') {
        this.$message.error('订单异常，将自动返回商品详情页')
        this.$router.go(-2)
        return false
      }
      console.log(directBuyOrderHttpObj)
      const { data: res } = await this.$http.post(createOrderBySeckillUrl, directBuyOrderHttpObj)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.orderInfo = res.data
      this.beforePayHttpObj.out_trade_no = this.orderInfo.no
    },


    // 未付款订单的订单信息
    async getPaidOrderInfo(){
      let paidOrderInfo = this.$store.state.paidOrderInfo
      if (JSON.stringify(paidOrderInfo) == '{}') {
        this.$message.error('订单异常，将自动订单页')
        await this.$router.push({ path: '/userCenter/order' })
        return false
      }
      this.orderInfo = paidOrderInfo
      this.beforePayHttpObj.out_trade_no = this.orderInfo.no
    },
    // 预下单
    beforePay () {

    }
  }
}
</script>

<style scoped>

</style>
